import React, { useEffect, useState, useContext } from 'react'
import { useLocation } from 'react-router'
import { DatePicker, Modal, Button, Popconfirm, message, Calendar } from 'antd'
import { CloseOutlined, SaveOutlined, EditOutlined } from '@ant-design/icons'
import './container.scss'
import moment from 'moment'
import { extendMoment } from 'moment-range'
import $api from '../service/http'
import { TriggerContext } from '../context'
const { RangePicker } = DatePicker

const moments = extendMoment(moment)
function Container() {
  const [booking, setbooking] = useState([])
  const [statusroom, setstatusroom] = useState({ busyRooms: 0, freeRooms: 0 })
  const { trigger, setTrigger } = useContext(TriggerContext)
  const router = useLocation()
  useEffect(() => {
    $api
      .post(
        '/booking' + router.pathname + '',
        {},
        {
          auth: {
            username: localStorage.getItem('username'),
            password: localStorage.getItem('password'),
          },
        }
      )
      .then((res) => {
        setbooking(res.data.data.floors)
        setstatusroom({
          busyRooms: res.data.data.busyRooms,
          freeRooms: res.data.data.freeRooms,
        })
      })
  }, [router, trigger])

  useEffect(() => {
    $api
      .post(
        '/booking' + router.search + '',
        {},
        {
          auth: {
            username: localStorage.getItem('username'),
            password: localStorage.getItem('password'),
          },
        }
      )
      .then((res) => {
        setbooking(res.data.data.floors)
      })
      .catch((error) => {
        console.log(error?.response?.data?.message)
      })
  }, [router])

  const [XonaVisible, setIsXonaVisible] = useState(false)
  const [dates, setdates] = useState({ data: [], roomid: 0 })
  const [toDoList, setToDoList] = useState([])
  const [rangedatte, setrangedatte] = useState([])
  const [modalTitle, setmodalTitle] = useState(null)
  const [BookingId, setBookingId] = useState(1)
  const [Uzgar, setUzgar] = useState(null)

  const [disableDate, setDisableDate] = useState([])

  const DataDelete = (id) => {
    $api
      .post(
        '/booking/delete/' + id + '',
        {},
        {
          auth: {
            username: localStorage.getItem('username'),
            password: localStorage.getItem('password'),
          },
        }
      )
      .then((res) => {
        message.success(res.data.message)
      })
      .catch((error) => {
        message.error(error.response.data.message)
      })
    setUzgar(id)
    setTrigger(id)
  }

  const NewDataSave = (roomid, id, startedDate, finishedDate) => {
    document.getElementById(id).setAttribute('style', 'display:none;')
    $api
      .post(
        '/booking/new',
        {
          roomId: roomid,
          startedDate: startedDate,
          finishedDate: finishedDate,
        },
        {
          'Content-Type': 'application/json',
          auth: {
            username: localStorage.getItem('username'),
            password: localStorage.getItem('password'),
          },
        }
      )
      .then((res) => {
        message.success(res.data.message)
      })
      .catch((error) => {
        message.error(error.response.data.message)
      })
    setUzgar(id)
    setTrigger(id)
    setToDoList([])
  }

  const DataSave = (roomid, id, startedDate, finishedDate) => {
    document.getElementById(id).setAttribute('style', 'display:none;')
    $api
      .post(
        '/booking/edit',
        {
          id: id,
          roomId: roomid,
          startedDate: startedDate,
          finishedDate: finishedDate,
        },
        {
          'Content-Type': 'application/json',
          auth: {
            username: localStorage.getItem('username'),
            password: localStorage.getItem('password'),
          },
        }
      )
      .then((res) => {
        console.log(res)
        message.success(res.data.message)
      })
      .catch((error) => {
        message.error(error.response.data.message)
      })
    setUzgar(id)
    setTrigger(id)
  }

  const Xona = (e, number) => {
    console.log(e, number)
    setmodalTitle(number)
    setToDoList([])
    setBookingId(e)
    setUzgar(e)
    setTrigger(e)
    setIsXonaVisible(true)
  }
  useEffect(() => {
    $api
      .post(
        '/booking/room/' + BookingId + router.pathname + '',
        {},
        {
          auth: {
            username: localStorage.getItem('username'),
            password: localStorage.getItem('password'),
          },
        }
      )
      .then((res) => {
        setdates({ data: res.data.data.days, roomid: res.data.data.id })
        setDisableDate(res.data.data.days)
      })
  }, [Uzgar])

  const XonaCancel = (e) => {
    setIsXonaVisible(false)
    setUzgar(e)
    setTrigger(e)
  }
  const XonaSaqlash = (e) => {
    setIsXonaVisible(false)
    setUzgar(e)
    setTrigger(e)
  }

  const Yangiqushish = (e) => {
    setToDoList((prevState) => [...prevState, e])
    setUzgar(e)
    setTrigger(e)
  }
  const Yangiuchirish = (i) => {
    setToDoList((prevState) => prevState.filter((a, index) => index !== i))
    setUzgar(i)
    setTrigger(i)
  }

  useEffect(() => {
    document.querySelectorAll('.qavatlar').forEach(function (e) {
      e.addEventListener('contextmenu', function () {
        console.log('object')
      })
    })
  })

  function disabledDate(current) {
    const ranges = disableDate.map((date) => {
      const range = moments().range(
        date.startedDate,
        moment(date.finishedDate).add(1, 'days')
      )
      return range.contains(current)
    })
    return ranges.includes(true)
  }

  return (
    <div className='tinchlikplaza_bron_container'>
      <Modal
        title={`Xona ${modalTitle}`}
        visible={XonaVisible}
        onCancel={XonaCancel}
        wrapClassName='xona_modal'
        footer={null}
        onOk={XonaSaqlash}
      >
        <Calendar disabledDate={disabledDate} fullscreen={false} />
        {dates.data.map((date) => {
          if (date.clientId === null) {
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <RangePicker
                  style={{ width: '100%', margin: '10px 10px 10px 0' }}
                  ranges={{
                    Today: [moment(), moment()],
                    'This Month': [
                      moment().startOf('month'),
                      moment().endOf('month'),
                    ],
                  }}
                  defaultValue={[
                    moment(date.startedDate, 'YYYY/MM/DD'),
                    moment(date.finishedDate, 'YYYY/MM/DD'),
                  ]}
                  onChange={(i, e) => {
                    setrangedatte(e)
                    console.log(
                      dates.roomid,
                      date.bookingId,
                      rangedatte[0],
                      rangedatte[1]
                    )
                    document
                      .getElementById(date.bookingId)
                      .setAttribute(
                        'style',
                        'display:inline-block; margin: 0 0 0 10px;'
                      )
                    setUzgar(i)
                    setTrigger(i)
                  }}
                />
                <Popconfirm
                  title="O'chirmoqchimisiz"
                  okText='Ha'
                  cancelText="Yo'q"
                  onConfirm={() => DataDelete(date.bookingId)}
                >
                  <Button type='primary' shape='circle' icon={<CloseOutlined />} />
                </Popconfirm>

                <Button
                  id={date.bookingId}
                  onClick={() =>
                    DataSave(
                      dates.roomid,
                      date.bookingId,
                      rangedatte[0],
                      rangedatte[1]
                    )
                  }
                  style={{ margin: '0 0 0 10px', display: 'none' }}
                  type='primary'
                  shape='circle'
                  icon={<SaveOutlined />}
                />
              </div>
            )
          } else {
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <RangePicker
                  style={{ width: '100%', margin: '10px 10px 10px 0' }}
                  ranges={{
                    Today: [moment(), moment()],
                    'This Month': [
                      moment().startOf('month'),
                      moment().endOf('month'),
                    ],
                  }}
                  defaultValue={[
                    moment(date.startedDate, 'YYYY/MM/DD'),
                    moment(date.finishedDate, 'YYYY/MM/DD'),
                  ]}
                  onChange={(i, e) => {
                    setrangedatte(e)
                    console.log(
                      dates.roomid,
                      date.bookingId,
                      rangedatte[0],
                      rangedatte[1]
                    )
                    document
                      .getElementById(date.bookingId)
                      .setAttribute(
                        'style',
                        'display:inline-block; margin: 0 0 0 10px;'
                      )
                    setUzgar(i)
                    setTrigger(i)
                  }}
                />

                <Button type='primary' shape='circle' icon={<EditOutlined />} />
                <Popconfirm
                  title="O'chirmoqchimisiz"
                  okText='Ha'
                  cancelText="Yo'q"
                  onConfirm={() => DataDelete(date.bookingId)}
                >
                  <Button type='primary' shape='circle' icon={<CloseOutlined />} />
                </Popconfirm>
                <Button
                  id={date.bookingId}
                  onClick={() =>
                    DataSave(
                      dates.roomid,
                      date.bookingId,
                      rangedatte[0],
                      rangedatte[1]
                    )
                  }
                  style={{ margin: '0 0 0 10px', display: 'none' }}
                  type='primary'
                  shape='circle'
                  icon={<SaveOutlined />}
                />
              </div>
            )
          }
        })}
        {toDoList.map((toDo, is) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <RangePicker
                style={{ width: '100%', margin: '10px 10px 10px 0' }}
                ranges={{
                  Today: [moment(), moment()],
                  'This Month': [moment().startOf('month'), moment().endOf('month')],
                }}
                defaultValue={[
                  moment(moment(), 'YYYY/MM/DD'),
                  moment(moment(), 'YYYY/MM/DD'),
                ]}
                onChange={(i, e) => {
                  setrangedatte(e)
                  document
                    .getElementById(`new${is}`)
                    .setAttribute(
                      'style',
                      'display:inline-block; margin: 0 0 0 10px;'
                    )
                }}
              />
              <Button
                onClick={() => Yangiuchirish(is)}
                type='primary'
                danger
                shape='circle'
                icon={<CloseOutlined />}
              />
              <Button
                id={`new${is}`}
                onClick={() =>
                  NewDataSave(dates.roomid, `new${is}`, rangedatte[0], rangedatte[1])
                }
                style={{ margin: '0 0 0 10px', display: 'none' }}
                type='primary'
                danger
                shape='circle'
                icon={<SaveOutlined />}
              />
            </div>
          )
        })}
        <Button
          onClick={Yangiqushish}
          style={{ width: '100%', margin: '10px 0 0' }}
          type='primary'
        >
          Yangi qo'shish
        </Button>
      </Modal>
      {booking.map((e) => {
        return (
          <div className='qavatlar'>
            <div>{e.number}-qavat</div>
            <div>
              {e.rooms.map((b) => {
                return (
                  <div onClick={() => Xona(b.id, b.number)} status={b.status}>
                    <span>{b.number}</span>
                    <div>{b.type.slice(0, 3)}</div>
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
      <div className='band_footer'>
        <div>
          <div>
            <div></div>
          </div>
          <div>
            <p>Band xonalar</p>
            <p>{statusroom.busyRooms} ta</p>
          </div>
        </div>
        <div>
          <div>
            <div></div>
          </div>
          <div>
            <p>Bo'sh xonalar</p>
            <p>{statusroom.freeRooms} ta</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Container
