import React from 'react'
import { Form, Button, Select, message, InputNumber } from 'antd'
import $api from './service/http'

const { Option } = Select

function Addroom() {
  const [form] = Form.useForm()
  const onFinish = (values) => {
    $api
      .post(
        '/temp/room/add?floor=' +
          values.floor +
          '&number=' +
          values.number +
          '&type=' +
          values.type +
          '',
        {},
        {
          auth: {
            username: localStorage.getItem('username'),
            password: localStorage.getItem('password'),
          },
        }
      )
      .then((res) => {
        message.success(res.data.data)
      })
      .catch((error) => {
        message.error(error.response.data.message)
      })
    form.resetFields()
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <Form
      form={form}
      style={{
        position: 'absolute',
        transform: 'translate(-50%,-50%)',
        top: '50%',
        left: '45%',
      }}
      name='basic'
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete='off'
    >
      <Form.Item
        label='Raqami'
        name='number'
        rules={[{ required: true, message: 'Xona raqamini kiriting' }]}
      >
        <InputNumber
          placeholder={'Raqami'}
          style={{ width: '300px' }}
          min={0}
          max={1000}
        />
      </Form.Item>
      <Form.Item
        label='Qavat'
        name='floor'
        rules={[{ required: true, message: 'Qavatni tanlang' }]}
      >
        <Select placeholder={'Qavatni tanlang'} style={{ width: '300px' }}>
          <Option value={2}>2</Option>
          <Option value={3}>3</Option>
          <Option value={4}>4</Option>
          <Option value={5}>5</Option>
          <Option value={6}>6</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label='Turi'
        name='type'
        rules={[{ required: true, message: 'Iltimos xonani turini tanlang' }]}
      >
        <Select placeholder={'Xona turini'} style={{ width: '300px' }}>
          <Option value={'APT'}>APT</Option>
          <Option value={'CSU'}>CSU</Option>
          <Option value={'JSU'}>JSU</Option>
          <Option value={'SKS'}>SKS</Option>
          <Option value={'SKT'}>SKT</Option>
          <Option value={'SNG'}>SNG</Option>
          <Option value={'STT'}>STT</Option>
          <Option value={'STW'}>STW</Option>
          <Option value={'TRP'}>TRP</Option>
        </Select>
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type='primary' htmlType='submit' style={{ width: '300px' }}>
          Saqlash
        </Button>
      </Form.Item>
    </Form>
  )
}

export default Addroom
