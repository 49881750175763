import React, { useEffect, useState } from 'react'
import { DatePicker } from 'antd'
import moment from 'moment'
import './navbar.scss'
import { NavLink } from 'react-router-dom'
import SwiperCore, { Navigation, Mousewheel, A11y } from 'swiper'

import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
SwiperCore.use([Navigation, Mousewheel, A11y])
function Navbar() {
  const weekDay = [
    'Yakshanba',
    'Dushanba',
    'Seshanba',
    'Chorshanba',
    'Payshanba',
    'Juma',
    'Shanba',
  ]
  const [days, setdays] = useState([])
  const [datestr, setdatestr] = useState(moment())
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  function datepicer(value, dateString) {
    setdatestr(dateString)
  }
  useEffect(() => {
    setdays(
      Array.from({ length: moment(datestr).daysInMonth() }, (x, i) =>
        moment().startOf('month').add(i, 'days').format('DD')
      )
    )
  }, [datestr])
  return (
    <div className='tinchlikplaza_bron_navbar'>
      <DatePicker
        picker='month'
        defaultValue={moment()}
        onChange={datepicer}
        bordered={false}
        defaultPickerValue={moment()}
      />
      <div className='tinchlikplaza_bron_navbar_swiper'>
        <span className='prev' ref={navigationPrevRef}>
          <svg
            width='5'
            height='10'
            viewBox='0 0 5 10'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M4.5 1.25L0.75 5L4.5 8.75'
              stroke='black'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </span>
        <Swiper
          spaceBetween={5}
          slidesPerView={'auto'}
          slidesPerGroup={10}
          speed={1000}
          mousewheel
          freeMode
          // centeredSlides={true}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onSwiper={(swiper) => {
            setTimeout(() => {
              swiper.params.navigation.prevEl = navigationPrevRef.current
              swiper.params.navigation.nextEl = navigationNextRef.current
              swiper.navigation.destroy()
              swiper.navigation.init()
              swiper.navigation.update()
            })
          }}
        >
          {days.map((e) => {
            return (
              <SwiperSlide>
                <NavLink
                  to={`/${
                    e +
                    '.' +
                    moment(datestr).format('MM') +
                    '.' +
                    moment(datestr).format('YYYY')
                  }`}
                >
                  <span>{Number(e)}</span>
                  <span>
                    {
                      weekDay[
                        moment(
                          '' +
                            moment(datestr).format('YYYY') +
                            '-' +
                            moment(datestr).format('MM') +
                            '-' +
                            e +
                            ''
                        ).format('d')
                      ]
                    }
                  </span>
                </NavLink>
              </SwiperSlide>
            )
          })}
        </Swiper>
        <span className='next' ref={navigationNextRef}>
          <svg
            width='5'
            height='10'
            viewBox='0 0 5 10'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M0.5 1.25L4.25 5L0.5 8.75'
              stroke='black'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </span>
      </div>
    </div>
  )
}

export default Navbar
