import React, { useEffect, useState } from 'react'
import { Form, Input, Button, message } from 'antd'
import 'antd/dist/antd.css'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import Menu from './Menu/menu'
import Navbar from './Navbar/navbar'
import Container from './Container/container'
import { TriggerContext } from './context'
import Addroom from './addroom'
import moment from 'moment'
import Roomtype from './roomtype'
import axios from 'axios'

function App() {
  const history = useHistory()
  const router = useLocation()
  const [trigger, setTrigger] = useState(null)
  const [Data, setData] = useState({})


  const onFinish = (values) => {
    localStorage.setItem("username", values.username)
    localStorage.setItem("password", values.password)
    axios
      .post(
        'https://tinchlikplaza.softex.uz/api/v1/booking/' + moment().format('DD.MM.YYYY') + '',
        {},
        {
          auth: {
            username: values.username,
            password: values.password,
          },
        }
      )
      .then((res) => {
        setData(res)
      })
      .catch((error) => {
        setData({ status: 404 })
        message.error('Login yoki parol xato')
      })
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }


  window.addEventListener(
    'contextmenu',
    function (e) {
      e.preventDefault()
    },
    false
  )
  useEffect(() => {
    axios
      .post(
        'https://tinchlikplaza.softex.uz/api/v1/booking/' + moment().format('DD.MM.YYYY') + '',
        {},
        {
          auth: {
            username: localStorage.getItem('username'),
            password: localStorage.getItem('password'),
          },
        }
      )
      .then((res) => {
        setData(res)
      })
      .catch((error) => {
        setData({ status: 500 })
      })
  }, [router])

  return (
    <>
      {Data.status && (
        <>
          {Data.status === 200 ? (
            <Switch>
              <Route path={'/'} exact render={({ match }) => {
                history.push(moment().format('DD.MM.YYYY'))
                return <div />;
              }} />
              <Route path={'/admin/logout'} exact render={({ match }) => {
                history.push('/')
                setData([])
                localStorage.clear()
                return <div />;
              }} />
              <Route path={'/room/number'} exact>
                <Addroom />
              </Route>
              <Route path={'/room/type/:id'} exact>
                <Roomtype />
              </Route>
              <Route path={'/booking/room'} exact>
                <Menu />
                <Navbar />
                <Container />
              </Route>
              <TriggerContext.Provider value={{ trigger, setTrigger }}>
                <Route path={'/:id'} exact>
                  <Menu />
                  <Navbar />
                  <Container />
                </Route>
              </TriggerContext.Provider>
            </Switch>
          ) : (
            <Form
              style={{ position: 'absolute', transform: 'translate(-50%,-50%)', top: '50%', left: '50%' }}
              name='basic'
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete='off'
            >
              <Form.Item
                label='Username'
                name='username'
                rules={[
                  {
                    required: true,
                    message: 'Iltimos maydonni to\'ldiring!',
                  },
                ]}
              >
                <Input style={{ width: "300px" }} autoComplete='off' />
              </Form.Item>

              <Form.Item
                label='Password'
                name='password'
                rules={[
                  {
                    required: true,
                    message: 'Iltimos maydonni to\'ldiring!',
                  },
                ]}
              >
                <Input.Password style={{ width: "300px" }} autoComplete='off' />
              </Form.Item>

              <Form.Item>
                <Button type='primary' htmlType='submit' style={{ width: "100%" }}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          )
          }
        </>
      )}
    </>
  )
}

export default App
