import axios from "axios";

const $api = axios.create({
    baseURL: 'https://tinchlikplaza.softex.uz/api/v1',

})
axios.interceptors.request.use((config) => {
    return {
        headers: 'Basic admin admin',
        ...config
    }
})
export default $api